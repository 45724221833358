import React from "react";
import Badge from "@cx/ui/Badge";
import { toast } from "@cx/ui/Toast";
import { RO_STATUSES } from "../../../../src/features/repair-order/constants/csr.constants";
import { makeSecureRestApi } from "../../../api/xmmAxios";
import { getOffsetIsoString } from "../../utils/format";
import format from "../../repair-order/utils/format";
import moment from "moment";
import { assignPartCounterPersonToRO } from "../../past-quotes/services/csr-past-quotes.service";
import isEmpty from "lodash/isEmpty";
import {
  payTypeCodes,
  warrantySubmissionStates
} from "../../../constants/quote-status.constants";
import { PDFDocument } from "pdf-lib";
import {
  partStatusLabelWithSpecialOrderParts,
  partStatusWithSpecialOrderParts
} from "../../../constants/app.constants";

const getRONumberDisplay = quoteSummary => {
  if (!quoteSummary) {
    return "--";
  }
  return (
    quoteSummary.roNumber ??
    (quoteSummary.quoteStatus === "WITH_ADVISOR"
      ? "Pending"
      : quoteSummary.quoteStatus === "PRE_RO"
      ? "Pre RO"
      : "--")
  );
};

const keyCreatorRoNumber = quoteSummary => {
  if (!quoteSummary.value) {
    return "Pending";
  }
  return quoteSummary.value;
};

const buildPartStatusBadge = partStatus => {
  if (!partStatus) {
    return null;
  }

  return (
    <Badge
      color={partStatus === "Requested" ? "gray" : "purple"}
      htmlId="partStatusBadge"
    >
      {partStatus}
    </Badge>
  );
};

const buildPartWithSpecialOrderPartsStatusBadge = partObj => {
  if (partObj?.lifecycleState === partStatusWithSpecialOrderParts.APPROVED) {
    return (
      <Badge color="purple" htmlId="partsStatusWithSpecialOrderPartsBadge">
        {partStatusLabelWithSpecialOrderParts.APPROVED}
      </Badge>
    );
  }

  if (partObj?.lifecycleState === partStatusWithSpecialOrderParts.OPEN) {
    return (
      <Badge
        className="green-badge"
        htmlId="partsStatusWithSpecialOrderPartsBadge"
      >
        <i className="fa fa-play" />
        {partStatusLabelWithSpecialOrderParts.OPEN}
      </Badge>
    );
  }

  if (partObj?.lifecycleState === partStatusWithSpecialOrderParts.BACKORDERED) {
    return (
      <Badge
        className="yellow-badge"
        htmlId="partsStatusWithSpecialOrderPartsBadge"
      >
        <i className="fa fa-pause" />
        {partStatusLabelWithSpecialOrderParts.BACKORDERED}
      </Badge>
    );
  }

  return (
    <Badge color="gray" htmlId="partsStatusWithSpecialOrderPartsBadge">
      {partStatusLabelWithSpecialOrderParts.REQUESTED}
    </Badge>
  );
};

/**
 * Determine which advisor (if any) should be automatically assigned to an RO.
 * Business logic:
 * - Only do this if the RO is in "With advisor" status.
 * - If the currently assigned advisor is a valid advisor, keep them assigned.
 * - Else if the current user is a valid advisor, assign them.
 * - Else leave the advisor unassigned; the current user will have to select one.
 * NOTE: A return of `null` means that if there is an advisor assigned,
 *       they're not valid, so they should be unassigned.
 * @param {object} currentUser Current user.
 * @param {object} quoteSummary CSR data.
 * @param {array<object>} advisors List of actual advisors.
 * @returns {number|null} Numeric long id of the advisor who should be assigned,
 *                        or null if no advisor should be assigned.
 */
const determineAdvisorToAutoAssign = (currentUser, quoteSummary, advisors) => {
  const isWithAdvisor =
    quoteSummary.quoteStatus === RO_STATUSES.WITH_ADVISOR.value;

  if (isWithAdvisor) {
    const hasAssignedAdvisor = !!quoteSummary.serviceWriter;
    // Intentionally using == below since one API returns string ids and the other returns a number.
    const isAssignedAdvisorValid =
      hasAssignedAdvisor &&
      advisors.some(x => x.id == quoteSummary.serviceWriter);
    const isCurrentUserAnAdvisor = advisors.some(
      x => x.id == currentUser.userId
    );

    // If no advisor is assigned, but the current user is a valid advisor,
    // then the current user should be auto-assigned.
    if (!hasAssignedAdvisor && isCurrentUserAnAdvisor) {
      return currentUser.userId;
    }

    // If an advisor is assigned, but they're not a valid advisor, and the current user is,
    // then the current user should be auto-assigned.
    if (
      hasAssignedAdvisor &&
      !isAssignedAdvisorValid &&
      isCurrentUserAnAdvisor
    ) {
      return currentUser.userId;
    }

    // If an advisor is assigned, but they're not a valid advisor, and the current user isn't either,
    // then no advisor should be assigned. (The current user will have to select a valid advisor.)
    if (
      hasAssignedAdvisor &&
      !isAssignedAdvisorValid &&
      !isCurrentUserAnAdvisor
    ) {
      return null;
    }
  }

  // If none of the above scenarios match, just return the currently assigned advisor.
  return quoteSummary.serviceWriter;
};

const jsonPatchCSR = ({ appContext, confirmationId, ops }) => {
  const { dealer, user } = appContext;
  const { dealerCode } = dealer;
  ops.push({ op: "replace", path: "/lastModByUserId", value: user.id });

  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: `csr/dealerCode/${dealerCode}/${confirmationId}/jsonPatch`,
        method: "patch",
        data: ops
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to update RO."
    );
  });
};

// @note: patch API called to update csr status
const patchCSRStatus = ({ appContext, quoteSummary, status }) => {
  const { confirmationId, serviceWriter, hangTag, mileageIn } = quoteSummary;
  const { dealer, user } = appContext;
  const { dealerCode } = dealer;
  const restUrl = `quote/${dealerCode}/${confirmationId}`;
  let payload = {};
  // status is IN_PROCESS in case of WITH_ADVISOR
  if (status === "IN_PROCESS") {
    payload = {
      quoteStatus: status,
      serviceWriter,
      hangTag,
      // TEST HOOK: By putting this string in the Hang Tag field, it will cause the mileageIn
      // to be sent to the API as "X", which will trigger an actual backend validation error.
      mileageIn: hangTag.trim() === "TEST_HOOK_MILEAGE_IN_X" ? "X" : mileageIn,
      checkedInDateTime: moment().utc().format("YYYY-MM-DDTHH:mm:ss") + "+0000",
      lastModByUserId: user.quoteUserId
    };
  } else {
    payload = {
      quoteStatus: status,
      lastModByUserId: user.quoteUserId
    };
  }
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "patch",
        data: payload
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to update CSR status."
    );
  });
};

const updateTotalTaxes = ({ appContext, quoteSummary, totalTaxes }) => {
  const { confirmationId } = quoteSummary;
  const { dealer, user } = appContext;
  const { dealerCode } = dealer;
  const restUrl = `quote/${dealerCode}/${confirmationId}`;
  const payload = {
    totalTaxes,
    lastModByUserId: user.quoteUserId
  };
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "patch",
        data: payload
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to update taxes."
    );
  });
};

const getPayTypeSubTypes = dealerCode => {
  const restUrl = `internalpay/${dealerCode}/paytypegroup/Internal/subtype`;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get"
      },
      response => {
        const data = response.map(x => {
          return {
            ...x,
            subType: format.sentenceCase(x.subType)
          };
        });
        resolve(data);
      },
      error => {
        reject(error);
      },
      "Unable to retrieve pay type subtypes."
    );
  });
};

const getPayTypeCostAllocationTypes = dealerCode => {
  const restUrl = `internalpay/${dealerCode}/paytypegroup/Internal/costAllocation`;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get"
      },
      response => {
        const data = response.map(x => {
          return {
            ...x,
            subType: format.sentenceCase(x.subType)
          };
        });
        resolve(data);
      },
      error => {
        reject(error);
      }
    );
  });
};

const getTotalTaxes = ({ dealerCode, confirmationId }) => {
  const restUrl = `taxes/${dealerCode}/${confirmationId}/totaltax`;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get"
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to retrieve taxes."
    );
  });
};

/*
{
  confirmationId,
  lastModByUserId,
  quoteId,
  serviceWriter
}
*/
const patchQuoteAdvisor = (dealerCode, data) => {
  const restUrl = `quote/${dealerCode}/${data.confirmationId}`;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "patch",
        data
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to set advisor."
    );
  });
};

/**
 * Patches specific fields of a Repair Order or Quote.
 * @param {string} dealerCode Dealer code.
 * @param {string} confirmationId Quote confirmation id.
 * @param {object} delta Object with only the properties being updated.
 * @returns {Promise<object>}
 */
const patchRO = (dealerCode, confirmationId, modByUserId, delta) => {
  return new Promise((resolve, reject) => {
    delta.lastModByUserId = delta.lastModByUserId ?? modByUserId;
    makeSecureRestApi(
      {
        url: `quote/${dealerCode}/${confirmationId}`,
        method: "patch",
        data: delta
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to save changes."
    );
  });
};

const patchServiceTechnicans = (dealerCode, confirmationId, data) => {
  const restUrl = `quote/${dealerCode}/${confirmationId}`;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "patch",
        data
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to update technicians."
    );
  });
};

// serviceEstimate
const generateDoc = ({ dealerCode, quoteId, docName }) => {
  const restUrl = `csr/${dealerCode}/docs/${docName}/${quoteId}`;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        headers: { accept: "*/*", "Content-Type": "application/pdf" },
        responseType: "arraybuffer"
      },
      response => {
        const responseObj = {
          res: response,
          docName
        };
        resolve(responseObj);
      },
      error => {
        reject(error);
      },
      `Unable to generate document: ${docName}.`
    );
  });
};

const printSelectedDocuments = async (
  dealerCode,
  quoteId,
  docNames,
  quoteSummary
) => {
  // Execute the requests in parallel and wait for them to all finish.
  const promises = docNames.map(docName =>
    generateDoc({
      dealerCode,
      quoteId,
      docName
    })
  );
  const results = await Promise.allSettled(promises);
  // If any failed, let the user know.
  if (results.some(r => r.status === "rejected")) {
    toast.error(
      "One or more documents failed to generate. Please use the Documents button to find the documents and try again."
    );
  }
  // If any succeeded, open them.
  results
    .filter(r => r.status === "fulfilled")
    .forEach(async result => {
      // Load the PDF into PDFDocument
      const pdfDoc = await PDFDocument.load(result.value.res);
      const currentDocName = result.value.docName;
      // Set the PDF metadata title
      let title = null;
      if (currentDocName === "partsPickList") {
        title = `Pick list ${
          quoteSummary?.roNumber ? `- RO ${quoteSummary?.roNumber}` : ""
        }`;
      } else if (currentDocName === "serviceEstimate") {
        title = `Service Estimate ${
          quoteSummary?.roNumber ? `- RO ${quoteSummary?.roNumber}` : ""
        }`;
      } else if (currentDocName === "serviceInvoice") {
        title = `Service Invoice ${
          quoteSummary?.roNumber ? `- RO ${quoteSummary?.roNumber}` : ""
        }`;
      } else if (currentDocName === "techHardCard") {
        title = `Tech Hard Card ${
          quoteSummary?.roNumber ? `- RO ${quoteSummary?.roNumber}` : ""
        }`;
      } else if (currentDocName === "warranty") {
        title = `Warranty Copy ${
          quoteSummary?.roNumber ? `- RO ${quoteSummary?.roNumber}` : ""
        }`;
      } else if (currentDocName === "audit") {
        title = `Audit Copy ${
          quoteSummary?.roNumber ? `- RO ${quoteSummary?.roNumber}` : ""
        }`;
      } else if (currentDocName === "serviceContract") {
        title = `Service Contract ${
          quoteSummary?.roNumber ? `- RO ${quoteSummary?.roNumber}` : ""
        }`;
      }

      pdfDoc.setTitle(title, { showInWindowTitleBar: true });

      // Serialize the PDF to bytes (Uint8Array)
      const pdfBytes = await pdfDoc.save();

      // Create a Blob from the PDF bytes
      const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // ! Note this is done for erp, because iframe not allow to open pdf and blocking it on multiple browsers
      if (window.self !== window.top && currentDocName === "partsPickList") {
        // Create an anchor element to trigger the download
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = `${title}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        // Open the PDF in a new tab
        const newTab = window.open(pdfUrl, "_blank");
        // Clean up the Blob URL after the new tab has loaded the PDF
        newTab.onload = () => {
          URL.revokeObjectURL(pdfUrl);
        };
      }

      // *NOTE:- This code can be used for the Pdf download functionality
      // Create an anchor element to trigger the download
      // const link = document.createElement("a");
      // link.href = pdfUrl;
      // link.download = `${title}.pdf`;
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
    });
  return results;
};

const getDocumentsURL = ({ dealerCode, csrnumber }) => {
  const restUrl = `csr/dealercode/${dealerCode}/csrnumber/${csrnumber}/doc`;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get"
      },
      response => {
        resolve(response.url.replace("login-ex.jsp", "login-ext.jsp"));
      },
      error => {
        reject(error);
      },
      "Unable to retrieve documents location."
    );
  });
};

// Sometimes the "get quote details" API response contains payers
// that don't correspond to one or more service lines.
// This function filters those invalid payers out.
const getLegitPayers = (quoteSummary, dealerProperties) => {
  const legitPayTypeSet = new Set(
    quoteSummary?.quoteServices?.map(s => s.payTypeCode)
  );
  const hasServiceContract = legitPayTypeSet.has(payTypeCodes.SERVICE_CONTRACT);
  const hasWarranty = legitPayTypeSet.has(payTypeCodes.WARRANTY);
  const isUseOEMWarrantyEnabled =
    dealerProperties?.ENGG_USE_OEM_WARRANTY === "Y";
  let legitPayers = [];
  if (hasServiceContract || (hasWarranty && isUseOEMWarrantyEnabled)) {
    legitPayers = quoteSummary?.payers?.length
      ? quoteSummary.payers.filter(
          p =>
            legitPayTypeSet.has(p.payType) || p.payType == payTypeCodes.CUSTOMER
        )
      : [];
  } else {
    legitPayers = quoteSummary?.payers?.length
      ? quoteSummary.payers.filter(p => legitPayTypeSet.has(p.payType))
      : [];
  }

  return legitPayers;
};

// * Function for handling Top level menu popup component quote patch call
const patchCsrTopLevelMenuChanges = ({
  appContext,
  quoteSummary,
  csrPayload
}) => {
  const { confirmationId } = quoteSummary;
  const { dealer, user } = appContext;
  const { dealerCode } = dealer;
  const restUrl = `quote/${dealerCode}/${confirmationId}`;
  const payload = {
    ...csrPayload,
    lastModByUserId: user.quoteUserId
  };
  console.log(payload);
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "patch",
        data: payload
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to save changes."
    );
  });
};

const addWarrantyToService = (
  appContext,
  quoteServiceFromResponse,
  warrantyDetailsPayload
) => {
  // const { confirmationId } = quoteSummary;
  const { dealer, user } = appContext;
  const { dealerCode } = dealer;
  const restUrl = `service/${quoteServiceFromResponse?.quoteServiceId}/warranty/${dealerCode}`;
  const payload = {
    ...warrantyDetailsPayload,
    lastModByUserId: user.quoteUserId,
    lastModTime: getOffsetIsoString(new Date())
  };
  console.info("from addWarrantyService", payload, restUrl);
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "post",
        data: payload
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to save warranty details."
    );
  });
};

const updateWarrantyToService = ({
  appContext,
  selectedService,
  warrantyDetailsPayload
}) => {
  // const { confirmationId } = quoteSummary;
  const { dealer, user, dealerProperties } = appContext;
  const { dealerCode } = dealer;
  const isUseOEMWarrantyEnabled =
    dealerProperties?.ENGG_USE_OEM_WARRANTY === "Y" ? true : false;
  const restUrl = `service/${selectedService?.quoteServiceId}/warranty/${dealerCode}/${warrantyDetailsPayload?.warrantyId}`;
  const payload = {
    ...warrantyDetailsPayload,
    lastModByUserId: user.quoteUserId,
    lastModTime: getOffsetIsoString(new Date())
  };
  if (isUseOEMWarrantyEnabled) {
    payload.warrantyReadyDate =
      payload?.warrantySubmissionState === warrantySubmissionStates.READY
        ? payload?.warrantyReadyDate
          ? payload.warrantyReadyDate
          : getOffsetIsoString(new Date())
        : null;
  } else {
    delete payload.warrantyReadyDate;
    if (
      payload?.warrantySubmissionState === warrantySubmissionStates.SUBMITTED &&
      !payload?.warrantySubmissionDate
    ) {
      payload.warrantySubmissionDate = getOffsetIsoString(new Date());
    }
  }

  console.info("from updateWarrantyService", payload, restUrl);
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "put",
        data: payload
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to save changes to warranty details."
    );
  });
};

const deleteWarrantyToService = ({ appContext, selectedService }) => {
  // const { confirmationId } = quoteSummary;
  const { dealer } = appContext;
  const { dealerCode } = dealer;
  const restUrl = `service/${selectedService?.quoteServiceId}/warranty/${dealerCode}/${selectedService?.warranty?.warrantyId}`;

  console.info("from delete warranty froms service", restUrl);
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "delete"
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to remove warranty from service."
    );
  });
};

// @csr-logic
async function reAssignCounterPartsPerson(
  appContext,
  quoteSummary,
  currentUserId
) {
  if (
    isEmpty(quoteSummary.counterPartsPerson) !== true &&
    quoteSummary?.counterPartsPerson?.extUserId !== currentUserId
  ) {
    await assignPartCounterPersonToRO({
      appContext,
      quote: quoteSummary
    });

    toast.success(`RO ${quoteSummary.roNumber} has been reassigned to you`, {
      autoClose: 4000,
      closeOnClick: true
    });
  }
}

/**
 * Updates a service via a JSON PATCH API call.
 * @param {object} appContext App context.
 * @param {object} quoteSummary Full quote object.
 * @param {object} service Full service object.
 * @param {array<object>} operations Array of JSON PATCH operations to apply.
 * @returns {Promise<object>} Full updated quote object.
 */
const jsonPatchService = async (
  appContext,
  quoteSummary,
  service,
  operations
) => {
  const { confirmationId } = quoteSummary;
  const { dealer } = appContext;
  const { dealerCode } = dealer;
  const { quoteServiceId } = service;
  const filteredOperations = operations.filter(operation => {
    return ![
      "/lastModDateTime",
      "/defaultServiceTypeCode",
      "/dealerLaborRateId"
    ].includes(operation.path);
  });
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: `service/${quoteServiceId}/dealerCode/${dealerCode}/${confirmationId}/jsonPatch`,
        method: "patch",
        data: filteredOperations
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to complete request."
    );
  });
};

//* GET RO LEVEL DISCOUNTS

const getRoLevelCatalogDiscounts = dealerCode => {
  // TODO: update isNotExpired=1 when expiryDate is defect is fixed from catalog
  const restUrl = `quote/services/${dealerCode}/discounts?isNotExpired=1&isForRO=1`;

  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get"
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to fetch Discounts."
    );
  });
};

const getPurchaseOrderDetailsCall = (poNumber, dealerCode) => {
  const restUrl = `csr/emergencyparts/dealers/id/${dealerCode}/inventory-parts/purchase-orders/id/${poNumber}`;

  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get"
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to retrieve purchase order information.."
    );
  });
};

const calculateNewTotalWithoutDeferredServices = ({
  dealerCode,
  confirmationId,
  preRoServiceIds
}) => {
  const restUrl = `csr/dealerCode/${dealerCode}/confirmationId/${confirmationId}/calculateNewTotalWithoutDeferredServices`;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "post",
        data: preRoServiceIds
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to calculate total without deferred services."
    );
  });
};

export default {
  reAssignCounterPartsPerson,
  getRONumberDisplay,
  keyCreatorRoNumber,
  buildPartStatusBadge,
  buildPartWithSpecialOrderPartsStatusBadge,
  getPayTypeSubTypes,
  getPayTypeCostAllocationTypes,
  getTotalTaxes,
  determineAdvisorToAutoAssign,
  jsonPatchCSR,
  patchCSRStatus,
  patchRO,
  patchServiceTechnicans,
  patchQuoteAdvisor,
  updateTotalTaxes,
  generateDoc,
  printSelectedDocuments,
  getDocumentsURL,
  getLegitPayers,
  patchCsrTopLevelMenuChanges,
  addWarrantyToService,
  updateWarrantyToService,
  deleteWarrantyToService,
  jsonPatchService,
  getRoLevelCatalogDiscounts,
  getPurchaseOrderDetailsCall,
  calculateNewTotalWithoutDeferredServices
};
