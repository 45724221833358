/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import TextInput from "@cx/ui/TextInput";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import IconMaximize from "@cx/ui/Icons/IconMaximize";
import Tabs from "@cx/ui/Tabs";
import Tab from "@cx/ui/Tab";
import Grid from "@cx/ui/Grid";
import ModalDialog from "@cx/ui/ModalDialog";
import IconMinimize from "@cx/ui/Icons/IconMinimize";
import { useNewQuoteContext, Actions } from "../../../state/NewQuoteContext";
import format from "../../repair-order/utils/format";
import "./checkout-summary.scss";
import { AppContext } from "../../../state/app-context";
import {
  MODIFY_CATALOG_RO_FEE,
  MODIFY_CATALOG_RO_LEVEL_DISCOUNT,
  YES
} from "../../repair-order/constants/adjustment.constant";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import Button from "@cx/ui/Button";
import {
  PAYER_TYPES,
  RO_STATUSES
} from "../../repair-order/constants/csr.constants";
import quoteService from "../../quote-summary/services/edit-quote.service";
import {
  getActivePayersTab,
  getCurrentNumberOfServiceContracts,
  getPayerByType,
  hasClosedDatePassed
} from "../../repair-order/utils/payers.util";
import { toast } from "@cx/ui/Toast";
import CheckoutSplitPaymentComponent from "./checkout-split-payment.component";
import { setCacheValue } from "../../../utils/cache.util";
import { CACHE_NAMES } from "../../../constants/cache-names.constants";
import { formatVendorProduct } from "../../service-contract/utils/service-contracts.util";
import ModifyDeductibleModal from "./modify-deductible-modal.component";
import { addCatalogRoLevelFeesAndDiscounts } from "../../repair-order/components/catalog-fee/catalog-fee.service";
import { paymentStatus } from "../../../constants/quote-status.constants";
import { appTypes } from "../../../constants/app.constants";
import WarrantyDeductibleModalComponent from "./warranty-deductible-modal.component";
import { isDMSPlus } from "../../../api/app.util";

/*
Payer properties to use for each Totals row:

Totals row               Payer property
--------------------     --------------------------
Labor                    subTotalLineLaborBasePrice
Parts                    subTotalLinePartsBasePrice
Hazardous Materials      feesHazardousMaterials
Misc. Charges & Fees     miscChargesAndFees
Line Discounts           subTotalLineDiscounts
RO Discounts             subTotalRODiscounts
Sublets                  subTotalSublets
Subtotal                 subTotalBeforeTaxes
Sales Tax                salesTax
Total                    finalPrice
*/

const CheckoutSummaryComponent = () => {
  const appContext = useContext(AppContext);
  const { dealer, dealerProperties, appType } = appContext;
  const { state, dispatch, blockUntilCompleted } = useNewQuoteContext();
  const { quoteSummary, payTypes } = state;
  const serviceContractPayer = getPayerByType(quoteSummary, PAYER_TYPES.S);
  const customerPayer = getPayerByType(quoteSummary, PAYER_TYPES.C);
  const [showModal, setShowModal] = useState(false);
  const [modifyDeductibleModal, setModifyDeductibleModal] = useState({
    show: false,
    deductible: null,
    vendorName: null
  });
  const [modifyWarrantyDeductibleModal, setModifyWarrantyDeductibleModal] =
    useState({
      show: false,
      warrantyDeductible: null
    });
  const [payTypesUsedInRo, setPayTypesUsedInRo] = useState([]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [approvalCode, setApprovalCode] = useState({
    value: "",
    vendorName: "",
    hasChanged: false,
    isLoading: false,
    hasServiceContract: false,
    isClosed: false
  });

  const [warrantyAuthorizationCode, setWarrantyAuthorizationCode] = useState({
    value: "",
    hasChanged: false
  });

  const isFinalized = quoteSummary?.quoteStatus === RO_STATUSES.FINALIZED.value;

  const isDMSPlusDealerWithOtherPayer =
    isDMSPlus(dealer?.dmsType) &&
    serviceContractPayer?.serviceContract?.type === "OT";

  const payTypeCodes = payTypes.map(p => {
    return p.payCode;
  });
  const isSplitPaymentEnabled =
    dealerProperties.USE_SPLIT_PAYMENT_FEATURE === YES ? true : false;

  const isFeatureEnabled =
    appType === appTypes.CSR ||
    (appType === appTypes.SQ && dealerProperties?.ENABLE_CSR_APP === YES);

  const hideTab =
    isFeatureEnabled &&
    dealerProperties?.ENABLE_DMSPLUS_SERVICE_CONTRACT_E37501 === YES;

  const isUseOEMWarrantyEnabled =
    dealerProperties?.ENGG_USE_OEM_WARRANTY === YES ? true : false;
  // TEST HOOK
  if (location.href.includes("CAUSE_TOTALS_PANEL_ERROR_FOR_TESTING")) {
    throw new Error("Causing an intentional error for testing purposes.");
  }

  const modifyLinkRenderer = quote => {
    // Extract the first deductible from the quote transfers
    const deductible = quote?.transfers?.[0];

    // Find the related payers based on the deductible's target and source payers
    const relatedPayers = quote?.payers?.filter(
      payer =>
        payer.payerId === deductible?.targetPayer ||
        payer.payerId === deductible?.sourcePayer
    );

    // Determine if any of the related payers have a closed date
    const hasClosedPayers = relatedPayers?.some(payer => payer.closedDate);

    // Check if the deductible status is closed
    const isDeductibleClosed =
      deductible?.status === paymentStatus.CLOSED.toUpperCase();

    // Determine if the modify link should be enabled
    const enableModifyLink =
      !isFinalized && // Disable if finalized
      !hasClosedPayers && // Disable if any related payer is closed
      !approvalCode.isClosed && // Disable if the approval code is closed
      !isDeductibleClosed; // Disable if the deductible is closed

    // Render the modify button with appropriate properties
    return (
      <>
        <span> | </span>
        <Button
          className="catalog-ro-fee-modify"
          htmlId="editModifyLink"
          data-testid="editModifyLink"
          onClick={() =>
            setModifyDeductibleModal({
              show: true,
              deductible,
              vendorName: approvalCode?.vendorName
            })
          }
          buttonStyle="link"
          disabled={!enableModifyLink} // Disable button based on calculated condition
        >
          Modify
        </Button>
      </>
    );
  };

  const getWarrantyDeductibleLabel = () => {
    let deductibleLabel = "";
    if (quoteSummary?.transfers?.length) {
      const sourcePayer = quoteSummary?.payers.find(
        payee => payee.payerId === quoteSummary?.transfers[0]?.sourcePayer
      );
      const warranty = sourcePayer?.warranties?.find(
        warranty => warranty.code === quoteSummary?.transfers[0]?.name
      );
      if (sourcePayer?.payType === "W") {
        deductibleLabel = warranty
          ? `${warranty?.warrantyType} -
                ${warranty?.planName}`
          : "";
      }
    }
    return deductibleLabel;
  };

  const updateQuoteContext = quoteObject => {
    if (!isEmpty(quoteObject)) {
      dispatch({
        type: Actions.UPDATE_QUOTE,
        payload: quoteObject
      });
    }
  };

  const onHideModifyDeductibleModal = () => {
    setModifyDeductibleModal({ ...modifyDeductibleModal, show: false });
  };

  const onSaveDeductible = async payload => {
    await blockUntilCompleted(async () => {
      try {
        onHideModifyDeductibleModal();
        const response = await addCatalogRoLevelFeesAndDiscounts(
          appContext,
          quoteSummary,
          payload,
          payload?.payerId
        );

        updateQuoteContext(response);
      } catch (error) {
        console.log(error);
      }
    });
  };

  const onHideModifyWarrantyDeductibleModal = () => {
    setModifyWarrantyDeductibleModal({
      warrantyDeductible: null,
      show: false
    });
  };

  const onSaveWarrantyDeductible = async payload => {
    const payerId = quoteSummary?.payers?.find(
      payer => payer?.payType === "W"
    )?.payerId;
    const finalPayload = [
      {
        op: "replace",
        path: `/warranties/${payload.deductibleIndex}/deductible`,
        value: payload.value
      }
    ];
    await blockUntilCompleted(async () => {
      try {
        onHideModifyWarrantyDeductibleModal();
        const response = await quoteService.patchWarrantyDeductibles(
          appContext,
          quoteSummary,
          finalPayload,
          payerId
        );

        toast.success("Deductible  updated");
        dispatch({
          type: Actions.SET_UPDATE_PAYER,
          payload: response
        });
      } catch (error) {
        console.log(error);
      }
    });
  };

  useEffect(() => {
    if (quoteSummary?.quoteServices?.length > 0) {
      const payTypes = checkPayTypesUsedForQuote();
      setPayTypesUsedInRo(payTypes);
      setActiveTabIndex(getActivePayersTab(payTypes));
    }

    if (quoteSummary?.payers?.length > 0) {
      if (serviceContractPayer) {
        const hasServiceContract =
          !!serviceContractPayer?.serviceContract?.approvalCode;
        const currentApprovalCode =
          serviceContractPayer?.serviceContract?.approvalCode || "";
        const vendorName = formatVendorProduct(
          serviceContractPayer?.serviceContract?.vendorName,
          serviceContractPayer?.serviceContract?.productName
        );
        // const vendorName = serviceContractPayer?.serviceContract?.vendorName;
        const isClosed = hasClosedDatePassed(serviceContractPayer?.closedDate);

        setApprovalCode({
          ...approvalCode,
          value: currentApprovalCode,
          vendorName,
          hasServiceContract,
          isClosed
        });
      }

      const warrantyPayer = quoteSummary?.payers.find(
        payee => payee.payType === PAYER_TYPES.W
      );
      if (warrantyPayer) {
        setWarrantyAuthorizationCode({
          value:
            warrantyPayer?.warranties && warrantyPayer?.warranties?.length > 0
              ? warrantyPayer?.warranties[0]?.approvalCode
              : "",
          hasChanged: false
        });
      }
    }
  }, [quoteSummary]);

  // * getting payTypes used for quote
  const checkPayTypesUsedForQuote = () => {
    const usedPayType = [];
    quoteSummary?.payers?.map(payer => {
      payer?.payType && usedPayType.push(payer.payType);
    });

    return usedPayType;
  };

  const getROLevelFees = () => {
    return {
      text: "",
      valueGetter: (quote, payer) => payer.catalogFees
    };
  };

  const getROLevelDiscounts = () => {
    return {
      text: "",
      valueGetter: (quote, payer) => payer.catalogDiscounts
    };
  };

  // eslint-disable-next-line unused-imports/no-unused-vars
  const getWarrantyDeductibles = () => {
    return {
      text: "",
      valueGetter: (quote, payer) => payer.warranties
    };
  };
  const groups = [
    {
      name: "partsAndLabor",
      title: "Parts and labor",
      lines: [
        {
          text: "Labor",
          valueGetter: (quote, payer) => payer.subTotalLineLaborBasePrice
        },
        {
          text: "Parts",
          valueGetter: (quote, payer) => payer.subTotalLinePartsBasePrice
        }
      ]
    },
    //* NOTE: might be needed later
    // {
    //   name: "warrantyDeductibles",
    //   title: "Deductibles",
    //   lines: [getWarrantyDeductibles()]
    // },
    {
      name: "fees",
      title: "Fees",
      lines: [
        {
          text: "Line fees",
          valueGetter: (quote, payer) => payer.subTotalLineLaborFees
        },
        getROLevelFees()
      ]
    },
    {
      name: "discounts",
      title: "Discounts",
      lines: [
        {
          text: "Line discounts",
          valueGetter: (quote, payer) => payer.subTotalLineDiscounts
        },
        getROLevelDiscounts()
      ]
    },
    {
      name: "deductibles",
      title: "Deductibles",
      lines: [
        {
          text: null,
          textGetter: quote => {
            let deductibleLabel = "";
            if (isUseOEMWarrantyEnabled && quote?.transfers?.length) {
              const sourcePayer = quote?.payers.find(
                payee => payee.payerId === quote?.transfers[0]?.sourcePayer
              );
              const warranty =
                sourcePayer?.warranties &&
                sourcePayer?.warranties?.find(
                  warranty => warranty.code === quote?.transfers[0]?.name
                );
              if (sourcePayer?.payType === "W") {
                deductibleLabel = warranty
                  ? `${warranty?.warrantyType} -
                ${warranty?.planName}`
                  : "";
              } else {
                deductibleLabel = approvalCode?.vendorName;
              }
            } else {
              deductibleLabel = approvalCode?.vendorName;
            }

            return quote?.transfers?.length ? (
              <>
                {deductibleLabel} {modifyLinkRenderer(quote)}
              </>
            ) : (
              ""
            );
          },
          // valuegetter logic has to be refine to make sure we cover multiple transfers in future.
          valueGetter: (quote, payer) => {
            const payerType = payer.payType;
            const deductible =
              (quote?.transfers?.length && quote.transfers[0].amount) || 0;
            return (payerType === PAYER_TYPES.S ||
              payerType === PAYER_TYPES.W) &&
              deductible > 0
              ? `-${deductible}`
              : deductible;
          }
        }
      ]
    },
    {
      name: "sublets",
      title: null,
      lines: [
        {
          text: "Sublets",
          valueGetter: (quote, payer) => payer.subTotalSublets
        }
      ]
    },
    {
      name: "subtotalAndTax",
      title: null,
      lines: [
        {
          text: "Subtotal",
          valueGetter: (quote, payer) => payer.subTotalBeforeTaxes
        },
        {
          text: "Sales tax",
          valueGetter: (quote, payer) => payer.salesTax
        }
      ]
    }
  ];

  const detailedTotalData = [
    {
      text: "Total",
      valueGetter: (quote, payer) => payer.finalPrice
    }
  ];

  // Leave Service Duration code here since we'll probably add it back in after M2.
  // const serviceDurationData = [
  //   {
  //     text: "Service Duration",
  //     valueGetter: (quote, _payer) => {
  //       const singleLaborMinutes =
  //         quote.quoteServices?.reduce((minutes, service) => {
  //           return minutes + parseInt(service.labor?.laborTime ?? "0", 10);
  //         }, 0) ?? 0;
  //       const multiLaborMinutes =
  //         quote.quoteServices?.reduce((sum, service) => {
  //           return (
  //             service?.labors?.reduce((minutes, service) => {
  //               return minutes + parseInt(service.labor?.laborTime ?? "0", 10);
  //             }, 0) ?? 0
  //           );
  //         }, 0) ?? 0;
  //       const totalMinutes = singleLaborMinutes + multiLaborMinutes;
  //       const totalHours = totalMinutes / 60;
  //       return totalHours;
  //     }
  //   }
  // ];

  // For each pay type, sum up each subtotal for services that have that pay type.
  // For now, RO-level subtotals go under the "C" pay type.
  payTypeCodes.forEach(payType => {
    const payer = quoteSummary.payers?.find(x => x.payType === payType);
    if (!payer) {
      return;
    }
    groups.forEach(g => {
      g.lines.forEach(x => {
        x[payType] = x.valueGetter ? x.valueGetter(quoteSummary, payer) : 0;
        x["text"] = x.textGetter ? x.textGetter(quoteSummary) : x.text;
      });
    });
    detailedTotalData.forEach(x => {
      x[payType] = x.valueGetter ? x.valueGetter(quoteSummary, payer) : 0;
    });
    // Leave Service Duration code here since we'll probably add it back in after M2.
    // serviceDurationData.forEach(x => {
    //   x[payType] = x.valueGetter ? x.valueGetter(quoteSummary, payer) : 0;
    // });
  });

  // Temporary test flag so we can see all rows rendered
  if (location.href.includes("&useMockTotals=1")) {
    let value = 0;
    groups.forEach(g => {
      g.lines.forEach(x => {
        x.C = ++value;
      });
    });
    detailedTotalData.forEach(x => {
      x.C = 1234.56;
    });
    // Leave Service Duration code here since we'll probably add it back in after M2.
    // serviceDurationData.forEach(x => {
    //   x.C = 12.3;
    // });
  }

  const checkServiceLineClosed = payType => {
    const servicePayStatus = quoteSummary?.payers?.find(
      payer => payer?.payType === payType
    );
    const serviceLineClosed = servicePayStatus?.closedDate ? true : false;
    return serviceLineClosed;
  };

  const listRenderer = (item, payType, index) => {
    return item.lines
      .filter(c => c[payType] || !c.hideIfZero)
      .map((c, lineIndex) => {
        return isArray(c[payType])
          ? c[payType]?.map((feeOrDiscount, dataIndex) => {
              return (
                <div
                  key={`${
                    feeOrDiscount?.description || feeOrDiscount?.code
                  }-${index}-${payType}-${lineIndex}`}
                >
                  <Row
                    className={`list-row ro-totals-list-row ${
                      feeOrDiscount.description === "Sales Tax"
                        ? "sales-tax"
                        : ""
                    }`}
                  >
                    <Col
                      xs={8}
                      className={`${feeOrDiscount?.color ?? "Titanium700"}`}
                      data-testid={`label${index}${lineIndex}`}
                    >
                      {item?.name === "warrantyDeductibles"
                        ? feeOrDiscount?.code
                        : feeOrDiscount?.description}
                      <span> | </span>
                      <Button
                        className="catalog-ro-fee-modify"
                        htmlId={
                          item?.name === "discounts"
                            ? "catalog-ro-discount-modify"
                            : "catalog-ro-fee-modify"
                        }
                        data-testid={
                          item?.name === "discounts"
                            ? "catalog-ro-discount-modify"
                            : "catalog-ro-fee-modify"
                        }
                        onClick={() => {
                          if (item.name == "warrantyDeductibles") {
                            setModifyWarrantyDeductibleModal({
                              show: true,
                              warrantyDeductible: {
                                ...feeOrDiscount,
                                index: dataIndex
                              }
                            });
                          } else {
                            feeOrDiscount.feeDiscountPayerPayCode = payType;
                            dispatch({
                              type: Actions.SET_PRICE_ADJUSTMENT_ITEM,
                              payload: feeOrDiscount
                            });
                            dispatch({
                              type: Actions.SET_PRICE_ADJUSTMENT_TYPE,
                              payload:
                                item?.name === "discounts"
                                  ? MODIFY_CATALOG_RO_LEVEL_DISCOUNT
                                  : MODIFY_CATALOG_RO_FEE
                            });
                          }
                        }}
                        buttonStyle="link"
                        disabled={checkServiceLineClosed(payType)}
                        title={
                          checkServiceLineClosed(payType)
                            ? "Cannot be modified for closed services"
                            : ""
                        }
                      >
                        Modify
                      </Button>
                    </Col>
                    <Col
                      data-testid={`value${index}${lineIndex}`}
                      xs={4}
                      className="text-end Titanium800"
                    >
                      {item?.name === "warrantyDeductibles"
                        ? feeOrDiscount?.deductible === 0
                          ? format.currency(feeOrDiscount?.deductible)
                          : `- ${format.currency(feeOrDiscount?.deductible)}`
                        : item?.name === "discounts"
                        ? format.currency(feeOrDiscount?.appliedDiscount)
                        : format.currency(feeOrDiscount?.appliedFee)}
                    </Col>
                  </Row>
                </div>
              );
            })
          : c[payType] != null && (
              <div key={`${index}-${payType}-${activeTabIndex}-${lineIndex}`}>
                <Row
                  className={`list-row ro-totals-list-row ${
                    c.text === "Sales Tax" ? "sales-tax" : ""
                  }`}
                >
                  <Col
                    data-testid={`label${index}${lineIndex}`}
                    xs={9}
                    className={`${c.color ?? "Titanium700"}`}
                  >
                    {c.text}
                  </Col>
                  <Col
                    data-testid={`value${index}${lineIndex}`}
                    xs={3}
                    className="text-end Titanium800"
                  >
                    {format.currency(c[payType])}
                  </Col>
                </Row>
              </div>
            );
      });
  };

  const totalRenderer = (item, payType) => {
    return item.map(c => {
      return (
        <div key={`${c.text}-${payType}-${activeTabIndex}`}>
          <Row className="pay-type-text">
            <Col xs={6} data-testid={c.text + "label"}>
              {c.text}
            </Col>
            <Col
              xs={6}
              data-testid={c.text + "value"}
              className="total-pay-type text-end"
            >
              {format.currency(c[payType])}
            </Col>
          </Row>
          {/* Leave Service Duration code here since we'll probably add it back in after M2. */}
          {/* <div className="hr-border" /> */}
          {/* <Row className="list-row">
            <Col xs={6} style={{ color: "#6D727A" }}>
              {serviceDurationData[0].text}
            </Col>
            <Col xs={6} className="text-end Titanium800">
              {serviceDurationData[0][payType]}
            </Col>
          </Row> */}
        </div>
      );
    });
  };

  const detailedRenderer = item => {
    return item.lines.map(c => {
      return isArray(c?.C) || isArray(c?.I) || isArray(c.W) ? (
        roFeeRenderer(c, item.name)
      ) : (
        <div key={`${item.name}-${c.text}`}>
          <Row className="list-row">
            <Col xs={4} md={4}>
              {c.text}
            </Col>
            <Col
              md={2}
              xs={2}
              className={`text-center ${
                c.C >= 0.1 === true ? "Titanium800" : "Titanium700"
              }`}
            >
              {format.currency(c.C)}
            </Col>
            <Col
              md={2}
              xs={2}
              className={`text-center ${
                c.W >= 0.1 === true ? "Titanium800" : "Titanium700"
              }`}
            >
              {format.currency(c.W)}
            </Col>
            <Col
              md={2}
              xs={2}
              className={`text-center ${
                c.I >= 0.1 === true ? "Titanium800" : "Titanium700"
              }`}
            >
              {format.currency(c.I)}
            </Col>
            {payTypeCodes.includes(PAYER_TYPES.S) ? (
              <Col
                md={2}
                xs={2}
                className={`text-center ${
                  c.S >= 0.1 === true ? "Titanium800" : "Titanium700"
                }`}
              >
                {format.currency(c.S)}
              </Col>
            ) : null}
          </Row>
        </div>
      );
    });
  };

  const detailedTotalRenderer = item => {
    return item.map(c => {
      return (
        <Row className="total-row" key={`total-${c.text}-${activeTabIndex}`}>
          <Col xs={4} md={4}>
            {c.text}
          </Col>
          <Col
            md={2}
            xs={2}
            className={`text-center ${
              c.C >= 0.1 === true ? "Titanium800" : "Titanium700"
            }`}
          >
            {format.currency(c.C)}
          </Col>
          <Col
            md={2}
            xs={2}
            className={`text-center ${
              c.W >= 0.1 === true ? "Titanium800" : "Titanium700"
            }`}
          >
            {format.currency(c.W)}
          </Col>
          <Col
            md={2}
            xs={2}
            className={`text-center ${
              c.I >= 0.1 === true ? "Titanium800" : "Titanium700"
            }`}
          >
            {format.currency(c.I)}
          </Col>
          {payTypeCodes.includes(PAYER_TYPES.S) ? (
            <Col
              md={2}
              xs={2}
              className={`text-center ${
                c.S >= 0.1 === true ? "Titanium800" : "Titanium700"
              }`}
            >
              {format.currency(c.S)}
            </Col>
          ) : null}
        </Row>
      );
    });
  };

  const roFeeRenderer = (item, itemCategory) => {
    return (
      <React.Fragment key={`${itemCategory}`}>
        {item?.C
          ? item?.C?.map(c => (
              <Row
                className="list-row"
                key={`${itemCategory}-${activeTabIndex}-${c?.description}`}
              >
                <Col xs={4} md={4}>
                  {c?.description}
                </Col>
                <Col
                  md={2}
                  xs={2}
                  className={`text-center ${
                    c.appliedFee >= 0.1 === true ? "Titanium800" : "Titanium700"
                  }`}
                >
                  {format.currency(c.appliedFee)}
                </Col>
                <Col md={2} xs={2} className={`text-center ${"Titanium700"}`}>
                  {format.currency(0)}
                </Col>
                <Col md={2} xs={2} className={`text-center ${"Titanium700"}`}>
                  {format.currency(0)}
                </Col>
                {payTypeCodes.includes(PAYER_TYPES.S) ? (
                  <Col md={2} xs={2} className={`text-center ${"Titanium700"}`}>
                    {format.currency(0)}
                  </Col>
                ) : null}
              </Row>
            ))
          : null}
        {item?.W
          ? item?.W?.map(c => (
              <Row
                className="list-row"
                key={`${itemCategory}-${activeTabIndex}-${c?.description}`}
              >
                <Col xs={4} md={4}>
                  {c?.description}
                </Col>
                <Col
                  md={2}
                  xs={2}
                  className={`text-center ${
                    c.appliedFee >= 0.1 === true ? "Titanium800" : "Titanium700"
                  }`}
                >
                  {format.currency(0)}
                </Col>
                <Col
                  md={2}
                  xs={2}
                  className={`text-center ${
                    c.appliedFee >= 0.1 === true ? "Titanium800" : "Titanium700"
                  }`}
                >
                  {format.currency(c.appliedFee)}
                </Col>
                <Col md={2} xs={2} className={`text-center ${"Titanium700"}`}>
                  {format.currency(0)}
                </Col>
                {payTypeCodes.includes(PAYER_TYPES.S) ? (
                  <Col md={2} xs={2} className={`text-center ${"Titanium700"}`}>
                    {format.currency(0)}
                  </Col>
                ) : null}
              </Row>
            ))
          : null}
        {item?.I
          ? item?.I?.map(c => (
              <Row
                className="list-row"
                key={`${itemCategory}-${activeTabIndex}-${c?.description}`}
              >
                <Col xs={4} md={4}>
                  {c?.description}
                </Col>
                <Col md={2} xs={2} className={`text-center ${"Titanium700"}`}>
                  {format.currency(0)}
                </Col>
                <Col md={2} xs={2} className={`text-center ${"Titanium700"}`}>
                  {format.currency(0)}
                </Col>
                <Col
                  md={2}
                  xs={2}
                  className={`text-center ${
                    c.appliedFee >= 0.1 === true ? "Titanium800" : "Titanium700"
                  }`}
                >
                  {format.currency(c.appliedFee)}
                </Col>
                {payTypeCodes.includes(PAYER_TYPES.S) ? (
                  <Col md={2} xs={2} className={`text-center ${"Titanium700"}`}>
                    {format.currency(0)}
                  </Col>
                ) : null}
              </Row>
            ))
          : null}
      </React.Fragment>
    );
  };
  const onActivateTotalsTab = index => {
    setActiveTabIndex(index);
  };

  const handleCustomError = (label, value) => {
    if (!value && !approvalCode?.hasServiceContract) {
      return " ";
    }
  };

  const handleWarrantyAuthorizationError = (label, value) => {
    if (!value && !warrantyAuthorizationCode?.value) {
      return " ";
    }
  };

  const onChangeApprovalCode = ({ target }) => {
    setApprovalCode({ ...approvalCode, value: target.value, hasChanged: true });
  };

  const onChangeWarrantyAuthorizationCode = ({ target }) => {
    setWarrantyAuthorizationCode({ value: target.value, hasChanged: true });
  };

  const onSaveApprovalCode = async () => {
    if (serviceContractPayer) {
      try {
        setApprovalCode({ ...approvalCode, isLoading: true });
        const response = await quoteService.patchServiceContractApprovalCode(
          dealer.dealerCode,
          quoteSummary.confirmationId,
          serviceContractPayer.payerId,
          approvalCode.value || null
        );
        const currentNumberOfServices = getCurrentNumberOfServiceContracts(
          quoteSummary?.quoteServices,
          PAYER_TYPES.S
        );
        await setCacheValue(CACHE_NAMES.RO_PRICING, quoteSummary?.quoteId, {
          currentNumberOfServices
        });

        toast.success(
          response?.serviceContract?.approvalCode
            ? approvalCode.hasServiceContract
              ? "Service contract updated"
              : "Service contract approved"
            : "Service contract authorization removed"
        );

        dispatch({
          type: Actions.SET_UPDATE_PAYER,
          payload: response
        });
        setApprovalCode({
          ...approvalCode,
          isLoading: false,
          hasChanged: false,
          hasServiceContract: response?.serviceContract?.approvalCode
        });
      } catch (error) {
        setApprovalCode({ ...approvalCode, isLoading: false });
        console.log(error);
      }
    }
  };

  const onSaveWarrantyAuthorizationCode = async () => {
    const warrantyPayer = quoteSummary?.payers.find(
      payee => payee.payType === PAYER_TYPES.W
    );
    await blockUntilCompleted(async () => {
      try {
        const response = await quoteService.patchWarrantyAuthorizationCode(
          dealer.dealerCode,
          quoteSummary.confirmationId,
          warrantyPayer.payerId,
          warrantyAuthorizationCode.value || null
        );

        toast.success(
          response?.warranties[0]?.approvalCode
            ? "Warranty authorization updated"
            : "Warranty authorization removed"
        );

        dispatch({
          type: Actions.SET_UPDATE_PAYER,
          payload: response
        });
        setWarrantyAuthorizationCode({
          value:
            response?.warranties.length &&
            response?.warranties[0]?.approvalCode,
          hasChanged: false
        });
      } catch (error) {
        // setApprovalCode({ ...approvalCode, isLoading: false });
        console.log(error);
      }
    });
  };

  return (
    <>
      {modifyDeductibleModal.show ? (
        <ModifyDeductibleModal
          onCancel={onHideModifyDeductibleModal}
          onSave={onSaveDeductible}
          {...modifyDeductibleModal}
        />
      ) : null}

      {modifyWarrantyDeductibleModal.show ? (
        <WarrantyDeductibleModalComponent
          onCancel={onHideModifyWarrantyDeductibleModal}
          onSave={onSaveWarrantyDeductible}
          {...modifyWarrantyDeductibleModal}
        />
      ) : null}

      <Row className="rot-total-main">
        <Row className="rot-total-header">
          <Col xs={6}>Totals</Col>
          <Col xs={6} className="minmax-icon">
            <IconMaximize
              className="icon-maximize-minimize"
              htmlId="maximizeTotals"
              data-testid="maximizeTotals"
              onClick={() => setShowModal(true)}
              disabled={true}
            />
          </Col>
        </Row>
        <Row className="hr-title"> </Row>
        <Tabs
          htmlId="repairOrderTotalTab"
          role="tablist"
          active={activeTabIndex}
          onActivate={onActivateTotalsTab}
        >
          {payTypeCodes.includes("C") &&
          ((hideTab && payTypesUsedInRo.includes("C")) || !hideTab) ? (
            <Tab
              label="Customer"
              title={
                payTypesUsedInRo.includes("C")
                  ? "Customer"
                  : "No customer lines for this RO"
              }
              htmlId="customerTab"
              data-testid="customerTab"
              active={activeTabIndex === 0}
              disabled={!payTypesUsedInRo.includes("C")}
            >
              <div>
                <Grid className="ro-totals-grid">
                  <>
                    {groups.map((g, index) => {
                      const isDeductibles = g.name === "deductibles";
                      const hasTransfers = quoteSummary?.transfers?.length;
                      //* Note: might be required later
                      // const isWarrantyDeductibles =
                      //   g.name === "warrantyDeductibles";
                      // if (isWarrantyDeductibles) return null;
                      if (
                        isDeductibles &&
                        (!hasTransfers || isDMSPlusDealerWithOtherPayer)
                      )
                        return null;
                      return (
                        <GridContent
                          key={`${g.name}-${activeTabIndex}`}
                          g={g}
                          payType={PAYER_TYPES.C}
                          listRenderer={listRenderer}
                          index={index}
                        />
                      );
                    })}
                  </>
                  <div className="hr-border" />
                  {detailedTotalData
                    ? totalRenderer(detailedTotalData, PAYER_TYPES.C)
                    : null}
                </Grid>
              </div>
            </Tab>
          ) : null}
          {payTypeCodes.includes("W") &&
          ((hideTab && payTypesUsedInRo.includes("W")) || !hideTab) ? (
            <Tab
              label="Warranty"
              title={
                payTypesUsedInRo.includes("W")
                  ? "Warranty"
                  : "No warranty lines for this RO"
              }
              htmlId="warrantyTab"
              data-testid="warrantyTab"
              active={activeTabIndex === 1}
              disabled={!payTypesUsedInRo.includes("W")}
            >
              <Grid className="ro-totals-grid">
                <>
                  {groups.map((g, index) => {
                    const isDeductibles = g.name === "deductibles";
                    const hasTransfers = quoteSummary?.transfers?.length;
                    if (isDeductibles && !hasTransfers) return null;

                    //* Note: might be required later
                    // const isWarrantyDeductibles =
                    //   g.name === "warrantyDeductibles";
                    // if (isWarrantyDeductibles) return null;
                    // const isDeductibles = g.name === "deductibles";
                    // const isWarrantyDeductibles =
                    //   g.name === "warrantyDeductibles";
                    // const warranties = quoteSummary?.payers?.find(
                    //   payer => payer?.payType === "W"
                    // )?.warranties;
                    // if (isWarrantyDeductibles && !isUseOEMWarrantyEnabled)
                    //   return null;
                    // if (
                    //   isWarrantyDeductibles &&
                    //   isUseOEMWarrantyEnabled &&
                    //   warranties === null
                    // )
                    //   return null;
                    // if (isDeductibles) return null;
                    return (
                      <div key={`${g.name}-${activeTabIndex}`}>
                        {g.title?.length ? <label>{g.title}</label> : null}
                        {listRenderer(g, "W", index)}
                        <div className="hr-border" />
                      </div>
                    );
                  })}
                </>
                <div className="hr-border" />
                {detailedTotalData
                  ? totalRenderer(detailedTotalData, "W")
                  : null}
              </Grid>
              {isUseOEMWarrantyEnabled && quoteSummary?.transfers?.length ? (
                <Row className="pay-type-text margin-top">
                  <Col data-testid="authorization-code" xs={12}>
                    {getWarrantyDeductibleLabel() ? (
                      <div>{getWarrantyDeductibleLabel()} authorization</div>
                    ) : null}
                  </Col>
                  <Col xs={9}>
                    <TextInput
                      htmlId="authorization-authorization"
                      data-testid="authorization-authorization"
                      className="Titanium800"
                      label=""
                      displayLabel={false}
                      name="authorizationCode"
                      onChange={onChangeWarrantyAuthorizationCode}
                      getError={handleWarrantyAuthorizationError}
                      value={warrantyAuthorizationCode?.value || ""}
                      disabled={isFinalized}
                      placeholder="Enter code or claim"
                    />
                  </Col>
                  <Col xs={3} className="apply-button-col">
                    <Button
                      buttonStyle="secondary"
                      htmlId="applyButton"
                      data-testid="applyButton"
                      className="apply-button"
                      onClick={onSaveWarrantyAuthorizationCode}
                      disabled={
                        isFinalized || !warrantyAuthorizationCode?.hasChanged
                      }
                    >
                      {warrantyAuthorizationCode?.value ? "Update" : "Apply"}
                    </Button>
                  </Col>
                </Row>
              ) : null}
            </Tab>
          ) : null}
          {payTypeCodes.includes("I") &&
          ((hideTab && payTypesUsedInRo.includes("I")) || !hideTab) ? (
            <Tab
              label="Internal"
              title={
                payTypesUsedInRo.includes("I")
                  ? "Internal"
                  : "No internal lines for this RO"
              }
              htmlId="internalTab"
              data-testid="internalTab"
              active={activeTabIndex === 2}
              disabled={!payTypesUsedInRo.includes("I")}
            >
              <Grid className="ro-totals-grid">
                <>
                  {groups.map((g, index) => {
                    return g.name !== "deductibles" &&
                      g.name !== "warrantyDeductibles" ? (
                      <div key={`${g.name}-${activeTabIndex}`}>
                        {g.title?.length ? <label>{g.title}</label> : null}
                        {listRenderer(g, "I", index)}
                        <div className="hr-border" />
                      </div>
                    ) : null;
                  })}
                </>
                <div className="hr-border" />
                {detailedTotalData
                  ? totalRenderer(detailedTotalData, "I")
                  : null}
              </Grid>
            </Tab>
          ) : null}
          {payTypeCodes.includes(PAYER_TYPES.S) &&
          ((hideTab && payTypesUsedInRo.includes(PAYER_TYPES.S)) ||
            !hideTab) ? (
            <Tab
              label="Other payer"
              title={
                payTypesUsedInRo.includes(PAYER_TYPES.S)
                  ? "Service contract"
                  : "No Svc Ct lines for this RO"
              }
              htmlId="serviceCtrTab"
              data-testid="serviceCtrTab"
              active={activeTabIndex === 3}
              disabled={!payTypesUsedInRo.includes(PAYER_TYPES.S)}
            >
              <Grid className="ro-totals-grid">
                <>
                  {groups.map((g, index) => {
                    const isDeductibles = g.name === "deductibles";
                    const hasTransfers = quoteSummary?.transfers?.length;

                    //* Note: might be required later
                    // const isWarrantyDeductibles =
                    //   g.name === "warrantyDeductibles";
                    // if (isWarrantyDeductibles) return null;
                    if (
                      isDeductibles &&
                      (!hasTransfers || isDMSPlusDealerWithOtherPayer)
                    )
                      return null;
                    return (
                      <GridContent
                        key={`${g.name}-${activeTabIndex}`}
                        g={g}
                        payType={PAYER_TYPES.S}
                        listRenderer={listRenderer}
                        index={index}
                      />
                    );
                    // (
                    //   <div key={g.name}>
                    //     {g.title?.length ? <label>{g.title}</label> : null}
                    //     {listRenderer(g, PAYER_TYPES.S)}
                    //     <div className="hr-border" />
                    //   </div>
                    // );
                  })}
                </>
                <div className="hr-border" />
                {detailedTotalData
                  ? totalRenderer(detailedTotalData, PAYER_TYPES.S)
                  : null}
              </Grid>
              {!isDMSPlusDealerWithOtherPayer && (
                <Row className="pay-type-text margin-top">
                  <Col data-testid="authorization-code" xs={12}>
                    {approvalCode?.vendorName ? (
                      <div>{approvalCode.vendorName} authorization</div>
                    ) : null}
                  </Col>
                  <Col xs={9}>
                    <TextInput
                      htmlId="approvalCode-authorization"
                      data-testid="approvalCode-authorization"
                      className="Titanium800"
                      label=""
                      displayLabel={false}
                      name="name"
                      onChange={onChangeApprovalCode}
                      getError={handleCustomError}
                      value={approvalCode.value}
                      disabled={
                        approvalCode.isLoading ||
                        approvalCode.isClosed ||
                        isFinalized
                      }
                      placeholder="Enter code or claim"
                    />
                  </Col>
                  <Col xs={3} className="apply-button-col">
                    <Button
                      buttonStyle="secondary"
                      htmlId="applyButton"
                      data-testid="applyButton"
                      className="apply-button"
                      onClick={onSaveApprovalCode}
                      disabled={
                        !approvalCode.hasChanged ||
                        (!approvalCode.value &&
                          !approvalCode.hasServiceContract) ||
                        approvalCode.value ===
                          serviceContractPayer?.serviceContract?.approvalCode ||
                        approvalCode.isLoading ||
                        approvalCode.isClosed ||
                        isFinalized
                      }
                    >
                      {approvalCode.hasServiceContract ? "Update" : "Apply"}
                    </Button>
                  </Col>
                </Row>
              )}
            </Tab>
          ) : null}
        </Tabs>
      </Row>

      <ModalDialog
        htmlId="roTotalsModal"
        data-testid="roTotalsModal"
        className="detailed-modal"
        show={showModal}
        displayCloseButton={false}
        onHide={() => setShowModal(false)}
      >
        <div id="rot-modal-header">
          <Row className="modal-header--row">
            <Col xs={11} md={11} className="modal-header--title">
              Totals
            </Col>
            <Col xs={1} md={1}>
              <div className="modal-header--icon">
                <IconMinimize
                  className="icon-maximize-minimize"
                  htmlId="minimizeTotals"
                  onClick={() => setShowModal(false)}
                />
              </div>
            </Col>
          </Row>
        </div>
        <div id="rot-body">
          <Grid>
            <Row className="modal-body--row">
              <Col xs={4} md={4}>
                {" "}
              </Col>
              <Col xs={2} md={2} className="text-center">
                Customer pay
              </Col>
              <Col xs={2} md={2} className="text-center">
                Warranty
              </Col>
              <Col xs={2} md={2} className="text-center">
                Internal
              </Col>
              {payTypeCodes.includes(PAYER_TYPES.S) ? (
                <Col xs={2} md={2} className="text-center">
                  Other payer
                </Col>
              ) : null}
            </Row>
            <div className="hr-border" />
            <>
              {groups.map(g => {
                return g.name === "warrantyDeductibles" ? null : g.name ===
                  "deductibles" ? (
                  quoteSummary?.transfers?.length &&
                  !isDMSPlusDealerWithOtherPayer ? (
                    <div key={`${g.name}-detail-${activeTabIndex}`}>
                      {g.title?.length ? <label>{g.title}</label> : null}
                      {detailedRenderer(g)}
                      <div className="hr-border" />
                    </div>
                  ) : null
                ) : (
                  <div key={`${g.name}-detail-${activeTabIndex}`}>
                    {g.title?.length ? <label>{g.title}</label> : null}
                    {detailedRenderer(g)}
                    <div className="hr-border" />
                  </div>
                );
              })}
            </>
            <div className="hr-border" />
            {detailedTotalData
              ? detailedTotalRenderer(detailedTotalData)
              : null}
          </Grid>
        </div>
        {isSplitPaymentEnabled ? (
          <CheckoutSplitPaymentComponent payer={customerPayer} />
        ) : null}
      </ModalDialog>
    </>
  );
};

const GridContent = ({ g, payType, listRenderer, index }) => (
  <div key={`${g.name}-${payType}-${index}`}>
    {g.title?.length ? <label data-testid={g.title}>{g.title}</label> : null}
    {listRenderer(g, payType, index)}
    <div className="hr-border" />
  </div>
);

export default CheckoutSummaryComponent;
